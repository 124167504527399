<template>
	<div>
		<!--  -->
		<div
			class="container_bg_first wrapper_container bg_center"
			:style="`background-image: url(${!$store.getters.GetIsH5 ? config.first_img : config.h5_first_img});`"
		>
			<div class="main_content">
				<h1 class="ke_common_title">{{ config.title }}</h1>
			</div>
		</div>
		<!--  -->
		<div class="container_bg_two wrapper_container">
			<div class="main_content">
				<p class="ke_common_msg" v-for="item in config.list_msg">{{ item }}</p>
			</div>
		</div>
		<!--  -->
		<div class="container_bg_three wrapper_container">
			<div class="main_content">
				<common-brief :mainContent="config.brief_config"></common-brief>
			</div>
		</div>
	</div>
</template>

<script>
import CommonBrief from '../Components/common_brief.vue'
export default {
	components: {
		CommonBrief
	},
	props: {
		config: {
			type: Object,
			require: true
		}
	}
}
</script>

<style scoped lang="scss">
.container_bg_first {
	position: relative;
	height: 990px;
	.main_content {
		position: absolute;
		bottom: 150px;
		left: 50%;
		transform: translateX(-50%);
		h1 {
			text-align: center;
			color: #fff;
			text-shadow: 0px 4px rgba(0, 0, 0, 0.5);
		}
	}
}
.container_bg_two {
	.main_content {
		padding: 150px 0;
		p {
			width: 80%;
			margin: 0 auto;
			color: #555770;
			&:not(:first-child) {
				margin-top: 50px;
			}
		}
	}
}
.container_bg_three {
	background-color: var(--ke-primary-deep-blue);
	.main_content {
		padding: 100px 0;
	}
}
@media screen and (max-width: 1246px) {
	.container_bg_first {
		.main_content {
			top: 50%;
			h1 {
				text-align: center;
				margin: 0 auto;
				font-size: 85px;
				width: 70%;
				text-shadow: 0px 4px rgba(0, 0, 0, 0.25);
			}
		}
	}
	.container_bg_two {
		.main_content {
			p {
				color: #333333;
			}
		}
	}
	.container_bg_three {
		.main_content {
			padding: 160px 0;
		}
	}
}
</style>
