<template>
	<div>
		<emploer-and-emploee :config="employee_config"></emploer-and-emploee>
	</div>
</template>

<script>
import EmploerAndEmploee from '../Components/emploer_and_emploee.vue'
import { employee_config } from './employee.config'
export default {
	components: {
		EmploerAndEmploee
	},
	data() {
		return {
			employee_config
		}
	}
}
</script>

<style scoped lang="scss"></style>
