<template>
	<div class="container" :style="{ flexDirection: mainContent.direction || 'row' }">
		<h1 class="ke_common_title">{{ mainContent.title }}</h1>
		<p class="ke_common_line"></p>
		<div class="content">
			<img :src="mainContent.img" alt="img" />
			<ul class="content_inner">
				<li v-for="item in mainContent.ul_msg" :key="item">
					<span class="circle"></span>
					<p>{{ item }}</p>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		mainContent: {
			type: Object,
			require: true
		}
	}
}
</script>

<style scoped lang="scss">
.container {
	h1 {
		text-align: center;
		color: #fff;
	}
	.content {
		margin: 100px;
		display: flex;
		justify-content: space-between;
		img {
			width: 47%;
			height: 100%;
		}
		.content_inner {
			width: 47%;
			li {
				position: relative;
			}
			p {
				padding-left: 50px;
				font-size: 25px;
				color: #fff;
				line-height: 40px;
			}
			.circle {
				position: absolute;
				top: 22px;
				left: 0;
				display: inline-block;
				width: 10px;
				height: 9.5px;
				border-radius: 50%;
				border: 4px solid var(--ke-primary-red);
			}
		}
	}
}
@media screen and (max-width: 1246px) {
	.container {
		h1 {
			font-size: 60px;
		}
		.content {
			margin: 100px 0 0 0;
			flex-direction: column;
			img {
				width: 100%;
			}
			.content_inner {
				margin-top: 100px;
				width: 100%;
				li {
					&:not(:first-child) {
						margin-top: 40px;
					}
					p {
						font-size: 35px;
					}
				}
			}
		}
	}
}
</style>
