const brief_config = {
	direction: 'row',
	img: require('../../../assets/ke-img/Employees/three-bg.webp'),
	title: 'Benefits to Employees',
	ul_msg: [
		'Get on-demand, instant access to their earned wages.',
		'Gain control over how and when they receive their pay, so they can save and track spending.',
		'Improve their financial knowledge with built-in tools and resources.',
		'Reduce stress and anxiety caused by cashflow issues.',
		'Have options for how they meet financial commitments, helping reduce the cost of personal debt'
	],
	link: 'https://play.google.com/store/apps/details?id=tifislice.loan.credit.cash.longterm&referrer=af_tranid%3Db6929S35Vu07MSmvqWOwOA%26shortlink%3Dloan%26pid%3DSMS%26c%3D%E8%90%A5%E9%94%80%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%26af_cost_value%3D0.0031%26af_cost_currency%3DUSD'
}

export const employee_config = {
	brief_config,
	first_img: require('../../../assets/ke-img/Employees/first-bg.webp'),
	h5_first_img: require('../../../assets/ke-img/Employees/h5-first-bg.webp'),
	title: 'TiFi for Employees',
	list_msg: [
		'70% of households save less than 10% of their monthly income, don’t save at all, or are net debtors.',
		'Fixed pay days hinder employees who want more flexibility and control over their finances. Employees should have choice over how they meet their financial commitments, and this is what EWA can give them.',
		'With on-demand, instant access to pay, employees can obtain emergency funds for a unexpected bill or expense, without incurring more debt, or requiring manual intervention from the Payroll or HR teams.'
	]
}
